export const WebsiteLogoColors = {
  discord: '#7289DA',
  facebook: '#227BEF',
  instagram: '#C83681',
  linkedIn: '#1569BF',
  nintendo: '#E4000F',
  patreon: '#F76959',
  pinterest: '#E60023',
  steam: '#171A21',
  twitch: '#9146FF',
  twitter: '#00ACED',
  vimeo: '#1AB7EA',
  xboxLive: '#19791A',
  youtube: '#FF0000',
  snapchat: '#000000',
  playstation: '#1D75CC',
  tiktok: '#FB2B54',
  duolingo: '#58CC02',
  paypal: '#00457C',
  venmo: '#3D95CE',
  cashapp: '#00C244',
  globe: '#34C759',
};
