import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {CARD_DETAILS_BOX_RADIUS} from '../../constants/cardViewConstants';
import {SHADOW_STYLE} from '../../../shared/styles/shadow';

const DetailsSectionBox = props => {
  const { classes, palette, children} = props;
  return <div style={{ backgroundColor: palette.secondaryColor }}
    className={classes.detailsSectionBoxRoot}>{children}</div>;
};

export default compose(
  withStyles(({theme, palette}) => ({
    detailsSectionBoxRoot: {
      borderRadius: CARD_DETAILS_BOX_RADIUS,
      padding: 10,
      boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.22)',
      fontWeight: 'bold'
    },
  })),
)(DetailsSectionBox);
