import React, { useRef, useEffect, useState } from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {FIELDS} from '../../../../constants/cardData';
import {AvatarRound, HeaderInterestBubble} from '../../../shared';
import {
  CARD_BORDER_RADIUS,
  AVATAR_SIZE,
  CARD_PADDING,
} from '../../../../constants/cardViewConstants';
import {HEADER_TRIANGLE_HEIGHT} from '../chevronConstants';
import { getHeaderTextStyles } from '../../../../../shared/styles/sharedHeaderStyles';
import { Modal } from '@material-ui/core';
import ImageViewer from "react-simple-image-viewer";

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

const ChevronHeader = ({classes, ...props}) => {
  const { data, height, palette, truncateLongText, condensed} = props;
  const [width, setWidth] = useState(0);
  const stageCanvasRef = useRef(null);
  const styles = getHeaderTextStyles(palette, condensed)
  const [visible, setVisible] = useState(false)

  const size = useWindowSize();

  // useEffect will run on stageCanvasRef value assignment
  useEffect(() => {

    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (stageCanvasRef.current) {
      setWidth(stageCanvasRef.current.offsetWidth);
    }

  }, [stageCanvasRef, size]);

  const [opacity, setOpacity] = useState(0)
  const headerImage = data.newHeaderImage ? data[FIELDS.newHeaderImage].uri : data[FIELDS.headerImageUri]

  useEffect(() => {
    const img = new Image();

    img.onload = function () {
      setOpacity(1)
    };

    img.src = headerImage;
  }, [data])

  function handleClick(evt) {
    evt.preventDefault()
    setVisible(true)
  }

  return (
    <div ref={stageCanvasRef} className={classes.header}>
      <div className={classes.headerTopArea} style={{ transition: 'opacity 0.15s ease', opacity, backgroundImage: `url(${headerImage})` }}>
        <div className={classes.headerImageWithTriangles}>
          <HeaderInterestBubble
            {...props}
            containerClassName={classes.interestBubble}
          />
          <div className={classes.headerTriangle} style={{
            borderLeftWidth: width / 2,
            borderRightWidth: width / 2,
            borderBottomColor: palette.backgroundColor,
            borderBottomWidth: height / 5
          }}></div>
        </div>
        <AvatarRound
          onClick={(evt) => handleClick(evt)}
          uri={
            data.newProfileImage
              ? data[FIELDS.newProfileImage].uri
              : data[FIELDS.profileImageUri]
          }
          containerClassName={classes.avatarWrapper}
          bottom={((height / 5) - (AVATAR_SIZE / 2)) + 10 }
        />
      </div>
      <div className={classes.headerBottomArea} style={{ backgroundColor: palette.backgroundColor }}>
        <p
          style={styles.name}
          className={classes.name}>
          {data[FIELDS.name]}
        </p>
        {!!data[FIELDS.position] && (
          <p
            style={styles.position}
            className={classes.position}>
            {data[FIELDS.position]}
          </p>
        )}
        {!!data[FIELDS.organization] && (
          <p
            style={styles.organization}
            className={classes.organization}
            ellipsizeMode={truncateLongText ? 'tail' : null}
            numberOfLines={truncateLongText ? 1 : null}>
            {data[FIELDS.organization]}
          </p>
        )}
      </div>

      <Modal
        disableEnforceFocus
        disableAutoFocus
        open={visible}
        onClose={() => setVisible(false)}
        classes={classes.modal}
      >
        <ImageViewer
          src={[data[FIELDS.profileImageUri]]}
          currentIndex={0}
          onClose={() => setVisible(false)}
          closeOnClickOutside={true}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
        />
      </Modal>
    </div>
  );
};

export default compose(
  withStyles((theme) => ({
    header: {},
    interestBubble: {
      position: 'absolute',
      marginTop: CARD_PADDING,
      marginLeft: CARD_PADDING
    },
    headerTopArea: {
      position: 'relative',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderTopRightRadius: CARD_BORDER_RADIUS,
      display: 'flex',
      flexDirection: 'column',
      zIndex: 0,
      transition: 'background-image 0.5s ease-in',
      height: 325,
      [theme.breakpoints.down('sm')]: {
        height: 266
      },
      [theme.breakpoints.down('xs')]: {
        height: 206
      },
    },
    headerImageWithTriangles: {
      flex: 1,
      borderTopRightRadius: CARD_BORDER_RADIUS
    },
    headerTriangle: {
      width: 0,
      height: 0,
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderBottomWidth: HEADER_TRIANGLE_HEIGHT,
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
      borderTopWidth: 0,
      position: 'absolute',
      bottom: 0,
    },
    headerImage: {
      width: '100%',
      borderTopRightRadius: CARD_BORDER_RADIUS,
      height: 325,
      [theme.breakpoints.down('sm')]: {
        height: 266
      },
      [theme.breakpoints.down('xs')]: {
        height: 206
      },
    },
    avatarWrapper: {
      alignSelf: 'center',
      position: 'absolute',
      zIndex: 100
    },
    headerBottomArea: {
      padding: 20,
      textAlign: 'center',
    },
    modal: {
      position: 'absolute',
      width: 400,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 8,
      textAlign: 'center'
    }
  })),
)(ChevronHeader);
