import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import { NORMAL_FONT_SIZE, CARD_BORDER_RADIUS } from '../../constants/cardViewConstants';
import {getBaseUrl, getUrl} from '../../../helpers';

const DetailsWebsiteButton = props => {
  const {classes, palette, website, handlePress, ...rest} = props;
  const { url, title } = website;

  return (
    <a href={getUrl(url)}
      {...rest}
      style={{ backgroundColor: palette.primaryColor, color: palette.primaryContrastColor }}
      className={classes.detailsWebsiteButtonTitle}
      target={'_blank'}
    >
      <span className={classes.detailsWebsiteButtonIcon}>
        <i style={{ fontSize: NORMAL_FONT_SIZE, color: palette.primaryContrastColor }}
          class={`fas fa-globe`}></i>
      </span>
      { title && <span className={classes.detailsWebsiteButtonText}>
        {title}
      </span> }
      { !title && getBaseUrl(url) }
    </a>
  );
};

export default compose(
  withStyles(({palette, theme}) => ({
    detailsWebsiteButtonIcon: {
      paddingRight: 7
    },
    detailsWebsiteButtonTitle: {
      fontSize: NORMAL_FONT_SIZE,
      lineHeight: `${NORMAL_FONT_SIZE}px`,
      textDecoration: 'none',
      display: 'inline-block',
      borderRadius: CARD_BORDER_RADIUS,
      marginRight: 20,
      marginTop: 20,
      padding: '7px 10px'
    },
  })),
)(DetailsWebsiteButton);
