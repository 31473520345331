import {
  NAME_FONT_SIZE_CONDENSED,
  NORMAL_FONT_SIZE_CONDENSED,
  NORMAL_FONT_SIZE,
  CARD_PADDING,
  NAME_FONT_SIZE,
} from '../../card/constants/cardViewConstants';

export const getHeaderTextStyles = (palette, condensed) => ({
  name: {
    alignSelf: 'center',
    fontSize: condensed ? NAME_FONT_SIZE_CONDENSED : NAME_FONT_SIZE,
    color: palette.primaryColor,
    marginHorizontal: CARD_PADDING,
    margin: 0,
    fontWeight: 'bold',
  },
  position: {
    alignSelf: 'center',
    fontSize: condensed ? NORMAL_FONT_SIZE_CONDENSED : NORMAL_FONT_SIZE,
    color: palette.backgroundContrastColor,
    margin: 0
  },
  organization: {
    alignSelf: 'center',
    fontSize: condensed ? NORMAL_FONT_SIZE_CONDENSED : NORMAL_FONT_SIZE,
    color: palette.backgroundContrastColor,
    margin: 0,
    opacity: 0.7,
  },
});
