import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {FIELDS} from '../../constants/cardData';
import {CARD_BORDER_RADIUS} from '../../constants/cardViewConstants';

const HeaderInterestBubble = props => {
  const { data, palette, containerClassName, classes} = props;

  if (!data[FIELDS.interest]) return null;

  return (
    <div id="interest-bubble" style={{ backgroundColor: palette.primaryColor }} className={`${classes.headerInterestBubble} ${containerClassName}`}>
      <p style={{ color: palette.primaryContrastColor }} className={classes.headerInterestBubbleText}>
        {data[FIELDS.interest]}
      </p>
    </div>
  );
};

export default compose(
  withStyles(({palette}) => ({
    headerInterestBubble: {
      borderRadius: CARD_BORDER_RADIUS,
      padding: '5px 10px'
    },
    headerInterestBubbleText: {
      fontWeight: 'bold',
      margin: 0
    },
  })),
)(HeaderInterestBubble);
