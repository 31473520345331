import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {IntroText, CardDetails} from '../../../shared';
import AddToMyCardsona from '../../../shared/addToMyCardsona';

const FullBody = ({classes, ...props}) => {
  const { data } = props

  return (
    <div className={classes.cardBody}>
      <IntroText {...props} />
      <AddToMyCardsona {...props} />
      <CardDetails {...props} />
    </div>
  );
};

export default compose(
  withStyles(({palette}) => ({
    cardBody: {
      marginTop: -50
    }
  })),
)(FullBody);
