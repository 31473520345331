import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {NORMAL_FONT_SIZE} from '../../constants/cardViewConstants';

const DetailsSectionBoxRow = props => {
  const { classes, link, children, content, iconName, palette} = props;

  return (
    <a href={link || '#'} target={'_blank'} className={classes.detailsSectionBoxRowRoot}>
      <div className={classes.contentWrapper}>
        {children || <p style={{ color: palette.backgroundContrastColor }} className={classes.contentText}>{content}</p>}
      </div>
      <div className={classes.iconWrapper}>
        <p className={classes.contentText}>
          <i style={{ color: palette.backgroundContrastColor }}
             class={`fas fa-${iconName.toLowerCase()}`}></i>
        </p>
      </div>
    </a>
  );
};

export default compose(
  withStyles(({palette}) => ({
    detailsSectionBoxRowRoot: {
      flexDirection: 'row',
      display: 'flex',
      padding: '10px 0',
      textDecoration: 'none'
    },
    contentWrapper: {
      flex: 1
    },
    contentText: {
      fontSize: NORMAL_FONT_SIZE,
      fontWeight: 'bold',
      lineHeight: `${NORMAL_FONT_SIZE}px`,
      margin: 0
    },
    iconWrapper: {
      flex: 1,
      textAlign: 'right',
      lineHeight: `${NORMAL_FONT_SIZE}px`
    },
  })),
)(DetailsSectionBoxRow);
