import React, { useEffect, useState } from 'react';
import { compose } from '@truefit/bach';
import { withStyles } from '@truefit/bach-material-ui';
import { NORMAL_FONT_SIZE, CARD_BORDER_RADIUS } from '../../constants/cardViewConstants';
import QRCode from "react-qr-code";


const AddToMyCardsona = ({ classes, ...props }) => {
  const { data, palette } = props;
  const [link, setLink] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (data) {
      var linkData = {
        data: {
          'token': data.token,
          'fromUserId': data.userId
        }
      };

      window.branch.link(linkData, function (err, branchLink) {
        setLink(branchLink)
      });
    }
  }, [data])

  useEffect(() => {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
    ) {
      setIsMobile(true)
    }
  }, [navigator])

  return (
    isMobile ? (
      <a className={classes.addToMyCardsona} href={link}>
        Save This Cardsona

        <i style={{ color: '#2679FF', fontSize: 16, marginLeft: 16 }}
          class={`fas fa-chevron-right`}></i>
      </a>
    ) : (
        link ?
        <div>
          <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'row'}}>
            <div style={{ backgroundColor: 'white', padding: 20, margin: '0 auto', marginTop: 30, display: 'inline-block', justifyContent: 'center' }}>
              <QRCode bgColor={'#F6F65F'} fgColor={'#2679FF'} value={link} />
            </div>
          </div>
            <p className={classes.message} style={{ color: palette.backgroundContrastColor }}>Save this  Cardsona by scanning with a mobile device.</p>
        </div> : null
    )
  )
};

export default compose(
  withStyles(() => ({
    message: {
      fontSize: NORMAL_FONT_SIZE,
      margin: '0 auto',
      marginTop: 30,
      textAlign: 'center',
      fontStyle: 'italic'
    },
    addToMyCardsona: {
      fontSize: NORMAL_FONT_SIZE * 1.3,
      lineHeight: `${NORMAL_FONT_SIZE * 1.3}px`,
      textDecoration: 'none',
      display: 'block',
      borderRadius: CARD_BORDER_RADIUS,
      padding: '15px 20px',
      textAlign: 'center',
      margin: '20px auto',
      width: 300,
      backgroundColor: '#F6F65F',
      color: '#2679FF'
    },
  })),
)(AddToMyCardsona);
