import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {
  CARD_PADDING,
  INTRO_TRIANGLE_WIDTH,
  NORMAL_FONT_SIZE,
  INTRO_TRIANGLE_HEIGHT,
  CARD_DETAILS_BOX_RADIUS,
} from '../../constants/cardViewConstants';
import {FIELDS} from '../../constants/cardData';

const IntroText = props => {
  const { classes, palette, data, showTriangle, squareBorder } = props;
  if (!data[FIELDS.intro]) return null;
  return (
    <div className={classes.introWrapper}>
      {showTriangle && <div className={classes.introTriangle} style={{ borderBottomColor: palette.secondaryColor}}></div>}
      <div style={{ backgroundColor: palette.secondaryColor, borderRadius: squareBorder ? 0 : null }} className={classes.introBox}>
        <p style={{ color: palette.backgroundContrastColor }} className={classes.introText}>{data[FIELDS.intro]}</p>
      </div>
    </div>
  );
};

export default compose(
  withStyles(() => ({
    introTriangle: {
      width: 0,
      height: 0,
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderLeftWidth: INTRO_TRIANGLE_WIDTH / 2,
      borderRightWidth: INTRO_TRIANGLE_WIDTH / 2,
      borderBottomWidth: INTRO_TRIANGLE_HEIGHT,
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
      borderTopWidth: 0,
      alignSelf: 'center',
      display: 'block',
      margin: '0 auto'
    },
    introBox: {
      borderRadius: CARD_DETAILS_BOX_RADIUS,
      padding: NORMAL_FONT_SIZE * 2,
    },
    introText: {
      fontSize: NORMAL_FONT_SIZE,
      margin: 0
    },
  })),
)(IntroText);
