import React, {useState} from 'react';
import {compose} from '@truefit/bach';
import { withStyles } from '@truefit/bach-material-ui';
import {FIELDS} from '../../../../constants/cardData';
import {AvatarRound, HeaderInterestBubble} from '../../../shared';
import {
  CARD_PADDING,
} from '../../../../constants/cardViewConstants';
import { getHeaderTextStyles } from '../../../../../shared/styles/sharedHeaderStyles';
import { Modal } from '@material-ui/core';
import ImageViewer from "react-simple-image-viewer";

const FullHeader = props => {
  const { data, palette, condensed, classes} = props;
  const styles = getHeaderTextStyles(palette, condensed)
  const [visible, setVisible] = useState(false)

  function handleClick(evt) {
    evt.preventDefault()
    setVisible(true)
  }

  return (
    <>
    <HeaderInterestBubble {...props} palette={palette} containerClassName={classes.interestBubble} />
    <div className={classes.header}>
      <AvatarRound
        onClick={(evt) => handleClick(evt)}
        uri={
          data.newProfileImage
            ? data[FIELDS.newProfileImage].uri
            : data[FIELDS.profileImageUri]
        }
        containerStyle={classes.avatarWrapper}
      />
      <p
        style={styles.name}>
        {data[FIELDS.name]}
      </p>
      {!!data[FIELDS.position] && (
        <p
          style={styles.position}>
          {data[FIELDS.position]}
        </p>
      )}
      {!!data[FIELDS.organization] && (
        <p
          style={styles.organization}>
          {data[FIELDS.organization]}
        </p>
      )}
    </div>
      <Modal
        disableEnforceFocus
        disableAutoFocus
        open={visible}
        onClose={() => setVisible(false)}
        classes={classes.modal}
      >
        <ImageViewer
          src={[data[FIELDS.profileImageUri]]}
          currentIndex={0}
          onClose={() => setVisible(false)}
          closeOnClickOutside={true}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
        />
      </Modal>
    </>
  );
};

export default compose(
  withStyles((theme) => ({
    header: {
      flexDirection: 'column',
      textAlign: 'center',
      height: '80vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    avatarWrapper: {
      alignSelf: 'center'
    },
    interestBubble: {
      marginTop: CARD_PADDING,
      display: 'inline-block'
    },
    modal: {
      position: 'absolute',
      width: 400,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 8,
      textAlign: 'center'
    }
  })),
)(FullHeader);
