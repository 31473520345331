import React, { useRef, useEffect, useState } from 'react';
import { compose } from '@truefit/bach';
import { withStyles } from '@truefit/bach-material-ui';
import { WebsiteLogoColors } from '../../constants/websiteLogoColors';
import { BrandCharacters } from '../../constants/brandCharacters';

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

const WebsiteLogo = ({ name, disabled, classes, ...rest }) => {
  const size = useWindowSize();
  const [width, setWidth] = useState(0);
  const stageCanvasRef = useRef(null);

  // useEffect will run on stageCanvasRef value assignment
  useEffect(() => {

    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (stageCanvasRef.current) {
      setWidth(stageCanvasRef.current.offsetWidth);
    }

  }, [stageCanvasRef, size]);

  const avatarWidth = width === 90 ? 90 : 58


  return (
    <div ref={stageCanvasRef} style={{ backgroundColor: disabled ? 'silver' : WebsiteLogoColors[name] }} className={classes.socialIcon} {...rest}>
      {name != 'globe' &&
        <i style={{ position: 'absolute', color: 'white', fontSize: (avatarWidth / 1.6) }} className={`icon-${name}`}></i>
      }

      {name == 'globe' &&
        <i style={{ position: 'absolute', color: 'white', fontSize: (avatarWidth / 1.6) }}
          class={`fas fa-${name.toLowerCase()}`}></i>
      }
    </div>
  );
};

export default compose(
  withStyles((theme) => ({
    socialIcon: {
      height: 90,
      width: 90,
      borderRadius: 90 / 2,
      margin: '1%',
      overflow: 'hidden',
      backgroundColor: 'blue',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        height: 58,
        width: 58,
        borderRadius: 58 / 2,
      },
    }
  })),
)(WebsiteLogo);
