import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {CARD_PADDING} from '../../../../constants/cardViewConstants';
import {IntroText, CardDetails} from '../../../shared';
import AddToMyCardsona from '../../../shared/addToMyCardsona';

const ClassicBody = ({classes, ...props}) => {
  const {backgroundColor} = props;

  return (
    <div className={classes.cardBody} style={{ backgroundColor }}>
      <div style={{ paddingBottom: CARD_PADDING}}>
        <IntroText {...props} squareBorder />
        <AddToMyCardsona {...props} />
      </div>
      <CardDetails {...props} withPadding />
    </div>
  );
};

export default compose(
  withStyles(() => ({
    cardBody: {

    },
  })),
)(ClassicBody);
