import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import { HEADER_IMAGE_SAMPLE_SIZE } from '../../constants/cardViewConstants';
// import {BUTTON_LIST_BORDER_RADIUS} from '../../../forms/components/formButtonList';
import {SHADOW_STYLE} from '../../../shared/styles/shadow';

const HeaderImageSample = props => {
  const {styles, source, uri} = props;
  return (
    <div style={styles.headerImageWrapper}>
      <img
        style={styles.headerImage}
        src={
          source || {
            uri,
            //priority: FastImage.priority.high,
          }
        }
      />
    </div>
  );
};

export default compose(
  withStyles(({size = HEADER_IMAGE_SAMPLE_SIZE, containerStyle = {}}) => ({
    headerImageWrapper: {
      borderRadius: 22,
      borderTopLeftRadius: 0,
      width: size,
      height: size,
      ...SHADOW_STYLE,
      ...containerStyle,
    },
    headerImage: {
      width: size,
      height: size,
      //borderRadius: BUTTON_LIST_BORDER_RADIUS,
      borderTopLeftRadius: 0,
    },
  })),
)(HeaderImageSample);
