import React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import {SnackbarProvider} from 'notistack';
import theme from './styles/material-theme';
import App from './App';

const Root = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <App {...props} />
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default Root
