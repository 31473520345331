import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {DetailsSection} from '.';
import WebsiteLogoButton from './websiteLogoButton.js';
import {withHandlers} from '@truefit/bach-recompose/lib/enhancers';
import {FIELDS} from '../../constants/cardData';
import {handleError} from '../../../helpers';
import { socialUrl } from '../services/socialUrl';

const BUTTON_SIZE = 15;

const DetailsSocialButtonGroup = props => {
  const {names, title, ...rest} = props;
  const {
    data,
    classes,
    handlePress,
    externalSocialMediaAccountType,
    handleExternalMediaAccountPress,
  } = props;

  const customSocialMedia = data[FIELDS.externalSocialMediaAccounts].filter(
    x => x.type === externalSocialMediaAccountType,
  );

  const noNames = !names || names.length == 0 || names.every(n => !data[n]);
  const noCustomSocialMedia = customSocialMedia.length == 0;

  if (noNames && noCustomSocialMedia) return null;

  return (
    <DetailsSection {...props} title={title}>
      <div className={classes.root}>
        {names
          .filter(n => data[n])
          .map((n, i) => (
            <WebsiteLogoButton
              key={i}
              {...rest}
              name={n}
              username={data[n]}
              link={socialUrl(n, data[n])}
              size={BUTTON_SIZE}
            />
          ))}
        {customSocialMedia.map((n, i) => {
          return (
            <WebsiteLogoButton
              key={i}
              {...rest}
              name={'globe'}
              link={n.url}
              username={n.alias}
              size={BUTTON_SIZE}
            />
          );
        })}
      </div>
    </DetailsSection>
  );
};

export default compose(
  withStyles(() => ({
    root: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  })),
)(DetailsSocialButtonGroup);
