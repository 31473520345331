import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {DetailsSection, DetailsWebsiteButton} from '.';

const DetailsWebsiteButtonGroup = props => {
  const { websiteObjects, classes, ...rest} = props;

  return (
    websiteObjects &&
    websiteObjects.length > 0 && (
      <DetailsSection {...props} title="Websites">
        <div className={classes.root}>
          {websiteObjects.map((w, i) => (
            <DetailsWebsiteButton {...rest} key={i} website={w} />
          ))}
        </div>
      </DetailsSection>
    )
  );
};

export default compose(
  withStyles(({palette}) => ({
    root: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  })),
)(DetailsWebsiteButtonGroup);
