import React, { useState, useEffect } from 'react';
import {compose, withMemo} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {FIELDS} from '../../../../constants/cardData';
import {AvatarRound, HeaderInterestBubble} from '../../../shared';
import {
  CARD_BORDER_RADIUS,
  AVATAR_SIZE,
  AVATAR_SIZE_SM,
  CARD_PADDING,
} from '../../../../constants/cardViewConstants';
import {getHeaderTextStyles} from '../../../../../shared/styles/sharedHeaderStyles';
import { Modal } from '@material-ui/core';
import ImageViewer from "react-simple-image-viewer";

const ClassicHeader = props => {
  const { data, classes, palette, truncateLongText, condensed, backgroundColor} = props;
  const styles = getHeaderTextStyles(palette, condensed)

  const [opacity, setOpacity] = useState(0)
  const [visible, setVisible] = useState(false)
  const headerImage = data.newHeaderImage ? data[FIELDS.newHeaderImage].uri : data[FIELDS.headerImageUri]

  useEffect(() => {
    const img = new Image();

    img.onload = function () {
      setOpacity(1)
    };

    img.src = headerImage;
  }, [data])

  function handleClick(evt) {
    evt.preventDefault()
    setVisible(true)
  }

  return (
    <div className={classes.header}>
      <div className={classes.headerTopArea} style={{ transition: 'opacity 0.15s ease', opacity, backgroundImage: `url(${headerImage})` }}>
        <HeaderInterestBubble
          {...props}
          containerClassName={classes.interestBubble}
        />
      </div>
      <div className={classes.headerBottomArea} style={{ backgroundColor }}>
        <AvatarRound
          onClick={(evt) => handleClick(evt)}
          uri={
            data[FIELDS.newProfileImage]
              ? data[FIELDS.newProfileImage].uri
              : data[FIELDS.profileImageUri]
          }
          containerClassName={classes.avatarWrapper}
        />
        <p
          style={styles.name}
          className={classes.cardName}
          ellipsizeMode={truncateLongText ? 'tail' : null}
          numberOfLines={truncateLongText ? 1 : null}>
          {data[FIELDS.name]}
        </p>
        {!!data[FIELDS.position] && (
          <p
            style={styles.position}
            className={classes.position}
            ellipsizeMode={truncateLongText ? 'tail' : null}
            numberOfLines={truncateLongText ? 1 : null}>
            {data[FIELDS.position]}
          </p>
        )}
        {!!data[FIELDS.organization] && (
          <p
            style={styles.organization}
            className={classes.organization}
            ellipsizeMode={truncateLongText ? 'tail' : null}
            numberOfLines={truncateLongText ? 1 : null}>
            {data[FIELDS.organization]}
          </p>
        )}
      </div>
      <Modal
        disableEnforceFocus
        disableAutoFocus
        open={visible}
        onClose={() => setVisible(false)}
        classes={classes.modal}
      >
        <ImageViewer
          src={[data[FIELDS.profileImageUri]]}
          currentIndex={0}
          onClose={() => setVisible(false)}
          closeOnClickOutside={true}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
        />
      </Modal>
    </div>
  );
};

export default compose(
  withMemo(
    'bottomCornersRadius',
    ({roundedBottomCorners}) => (roundedBottomCorners ? CARD_BORDER_RADIUS : 0),
    ['roundedBottomCorners'],
  ),
  withStyles((theme) => ({
      cardName: {
        fontSize: 36,
        letterSpacing: -2.34,
        marginTop: '12px !important'
      },
      interestBubble: {
        position: 'absolute',
        marginTop: CARD_PADDING,
        marginLeft: CARD_PADDING
      },
      headerTopArea: {
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderTopRightRadius: CARD_BORDER_RADIUS,
        flex: 1,
        zIndex: -1,
        height: 325,
        [theme.breakpoints.down('sm')]: {
          height: 266
        },
        [theme.breakpoints.down('xs')]: {
          height: 206
        },
      },
      avatarWrapper: {
        marginTop: -(AVATAR_SIZE / 2),
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
          marginTop: -(AVATAR_SIZE_SM / 2),
        },
      },
      headerBottomArea: {
        display: 'flex',
        flexDirection: 'column'
      },
      modal: {
        position: 'absolute',
        width: 400,
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 8,
        textAlign: 'center'
      }
    }),
  ),
)(ClassicHeader);
