import React, { useState } from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {withHandlers} from '@truefit/bach-recompose';
import {
  DetailsSection,
  DetailsSectionBox,
  DetailsSectionBoxRow,
  DetailsWebsiteButtonGroup,
  DetailsSocialButtonGroup,
} from '.';
import {
  FIELDS,
  ExternalSocialMediaAccountType,
} from '../../constants/cardData';
import {
  NORMAL_FONT_SIZE,
  CARD_DETAILS_BOX_RADIUS,
} from '../../constants/cardViewConstants';
import Masonry from 'react-masonry-css';
import { Modal } from '@material-ui/core';
import ImageViewer from "react-simple-image-viewer";

const CardDetails = ({getContentLink, classes, ...props}) => {
  const { data, palette, withPadding } = props;
  const [galleryIndex, setGalleryIndex] = useState(-1)
  const [visible, setVisible] = useState(false)
  const emailPhoneList = [
    {value: data[FIELDS.email], icon: 'paper-plane'},
    {value: data[FIELDS.mobilePhone], icon: 'phone'},
    {value: data[FIELDS.phone], icon: 'phone'},
  ].filter(x => x.value);
  const addressLine1 = data[FIELDS.addressStreet1];
  const addressLine2 = data[FIELDS.addressStreet2];
  const addressLine3 = [
    data[FIELDS.addressCity],
    data[FIELDS.addressState],
    data[FIELDS.addressZip],
  ]
    .filter(_ => !!_)
    .join(' ');

  const fullAddress = [addressLine1, addressLine3]
    .filter(_ => !!_)
    .join('+');
  let mapLink = '';

  if(fullAddress) {
    if ((navigator.platform.indexOf("iPhone") != -1) ||
       (navigator.platform.indexOf("iPad") != -1) ||
       (navigator.platform.indexOf("iPod") != -1) ||
       (navigator.platform.indexOf("Mac") != -1)) {
      mapLink = `http://maps.apple.com/?daddr=${fullAddress}`;
    } else {
      mapLink = `https://maps.google.com?daddr=${fullAddress}`;
    }
  }

  const websiteObjects = data[FIELDS.websiteObjects];

  let galleryImages = [data.newHeaderImage ? data[FIELDS.newHeaderImage].uri : data[FIELDS.headerImageUri]]
  galleryImages = galleryImages.concat(data[FIELDS.galleryImageUris])

  function handleClick(evt, galleryImage, index) {
    evt.preventDefault()
    setGalleryIndex(index)
    setVisible(true)
  }

  return (
    <div className={classes.cardDetailsRoot} style={{ padding: withPadding ? NORMAL_FONT_SIZE * 2 : null}}>
      <DetailsSocialButtonGroup
        title="Payment Networks"
        names={[
          FIELDS.cashapp,
          FIELDS.paypal,
          FIELDS.venmo,
        ]}
        externalSocialMediaAccountType={
          ExternalSocialMediaAccountType.PaymentNetworks
        }
        {...props}
      />
      <DetailsSocialButtonGroup
        title="Social Media"
        names={[
          FIELDS.facebook,
          FIELDS.instagram,
          FIELDS.linkedIn,
          FIELDS.twitter,
          FIELDS.snapchat,
          FIELDS.tiktok,
          FIELDS.pinterest,
          FIELDS.duolingo,
          FIELDS.yelp
        ]}
        externalSocialMediaAccountType={
          ExternalSocialMediaAccountType.SocialMedia
        }
        {...props}
      />
      <DetailsSocialButtonGroup
        title="Media Platforms"
        names={[
          FIELDS.youtube,
          FIELDS.vimeo,
          FIELDS.patreon
        ]}
        externalSocialMediaAccountType={
          ExternalSocialMediaAccountType.MediaPlatforms
        }
        {...props}
      />
      <DetailsSocialButtonGroup
        title="Gaming Networks"
        names={[
          FIELDS.twitch,
          FIELDS.steam,
          FIELDS.discord,
          FIELDS.xboxLive,
          FIELDS.nintendo,
          FIELDS.playstation,
        ]}
        externalSocialMediaAccountType={
          ExternalSocialMediaAccountType.GamingNetworks
        }
        {...props}
      />
      <DetailsWebsiteButtonGroup
        {...props}
        websiteObjects={websiteObjects}
      />
      {galleryImages && galleryImages.length > 0 && (
        <DetailsSection title="Gallery" {...props}>
          <Masonry
            className={classes.masonryGrid}
            columnClassName={classes.masonryGridColumn}
            breakpointCols={3}
          >
            {galleryImages.map((galleryImage, index) => {
              return (
                <a href='#' onClick={(evt) => handleClick(evt, galleryImage, index)}>
                  <img src={galleryImage} className={classes.galleryImage} />
                </a>
              )
            })}
          </Masonry>
          <Modal
            disableEnforceFocus
            disableAutoFocus
            open={visible}
            onClose={() => setVisible(false)}
            classes={classes.modal}
          >
            <ImageViewer
              src={galleryImages}
              currentIndex={galleryIndex}
              onClose={() => setVisible(false)}
              closeOnClickOutside={true}
              disableScroll={true}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)"
              }}
            />
          </Modal>
        </DetailsSection>
      )}
      {emailPhoneList.length > 0 && (
        <DetailsSection {...props} title="Contact Info">
          <DetailsSectionBox {...props}>
            {emailPhoneList.map((x, i) => (
              <div key={i}>
                <DetailsSectionBoxRow
                  {...props}
                  content={x.value}
                  link={getContentLink(x)}
                  iconName={x.icon}
                />
                {i < emailPhoneList.length - 1 && (
                  <hr
                    style={{
                      backgroundColor: palette.backgroundContrastColor,
                      opacity: 0.25,
                    }}
                  />
                )}
              </div>
            ))}
          </DetailsSectionBox>
        </DetailsSection>
      )}
      {(!!addressLine1 || !!addressLine2 || !!addressLine3) && (
        <DetailsSection {...props} title="Address">
          <div>
            <DetailsSectionBox {...props}>
              <DetailsSectionBoxRow {...props} link={mapLink} iconName="map-marker-alt">
                {!!addressLine1 && (
                  <p style={{ color: palette.backgroundContrastColor }}
                    className={classes.addressText}>{addressLine1}</p>
                )}
                {!!addressLine2 && (
                  <p style={{ color: palette.backgroundContrastColor }}
                    className={classes.addressText}>{addressLine2}</p>
                )}
                {!!addressLine3 && (
                  <p style={{ color: palette.backgroundContrastColor }}
                    className={classes.addressText}>{addressLine3}</p>
                )}
              </DetailsSectionBoxRow>
            </DetailsSectionBox>
          </div>
        </DetailsSection>
      )}
    </div>
  );
};

export default compose(
  withHandlers({
    getContentLink: () => contact => {
      return `${contact.icon === 'phone' ? 'tel' : 'mailto'}:${
        contact.value
      }`;
    }
  }),
  withStyles((theme) => ({
    cardDetailsRoot: {
      padding: '20px 0'
    },
    addressText: {
      fontSize: NORMAL_FONT_SIZE,
      lineHeight: `${NORMAL_FONT_SIZE}px`,
      margin: 0
    },
    galleryImage: {
      borderRadius: CARD_DETAILS_BOX_RADIUS,
      objectFit: 'cover',
      width: '100%',
      boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.22)'
    },
    masonryGrid: {
      display: 'flex',
      marginLeft: '-20px',
      width: 'auto',
      marginTop: 20
    },
    masonryGridColumn: {
      paddingLeft: '20px',
      backgroundClip: 'padding-box'
    },
    modal: {
      position: 'absolute',
      width: 400,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 8,
      textAlign: 'center'
    }
  })),
)(CardDetails);
