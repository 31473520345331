// import { screenWidthPercent } from '../../styles';

export const AVATAR_SIZE = 150;
export const AVATAR_SIZE_SM = 100;
export const HEADER_IMAGE_SAMPLE_SIZE = 100;
export const CARD_BORDER_RADIUS = 40;
export const CARD_PADDING = 20;
export const NAME_FONT_SIZE = 25;
export const NAME_FONT_SIZE_CONDENSED = 20;
export const NORMAL_FONT_SIZE = 16;
export const NORMAL_FONT_SIZE_CONDENSED = 15;
export const INTRO_TRIANGLE_WIDTH = 25;
export const INTRO_TRIANGLE_HEIGHT = 15;
export const CONTACT_MENU_BUTTON_SIZE = 50;
export const CONTACT_MENU_HEIGHT = CONTACT_MENU_BUTTON_SIZE + 20;
export const CARD_DETAILS_BOX_RADIUS = 10;
