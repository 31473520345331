export const websiteLinks = {
  discord: 'https://discordapp.com/users/',
  facebook: 'https:///www.facebook.com/',
  instagram: 'https://www.instagram.com/',
  linkedIn: 'https://www.linkedin.com/in/',
  //nintendo: no link found
  nintendo: null,
  cashapp: null,
  paypal: null,
  venmo: null,
  patreon: 'https://www.patreon.com/',
  pinterest: 'https://www.pinterest.com/',
  steam: 'https://steamcommunity.com/id/',
  twitch: 'https://www.twitch.tv/',
  twitter: 'https://twitter.com/',
  vimeo: 'https://www.vimeo.com/',
  xboxLive: 'https://account.xbox.com/en-us/profile?gamertag=',
  youtube: 'https://www.youtube.com/',
  snapchat: 'https://www.snapchat.com/add/',
  playstation: 'https://my.playstation.com/profile/',
  tiktok: 'https://www.tiktok.com/',
  duolingo: 'https://duolingo.com/profile/',
};
