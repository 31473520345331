import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import { AVATAR_SIZE, AVATAR_SIZE_SM } from '../../constants/cardViewConstants';

const AvatarRound = props => {
  const { classes, source, uri, size, containerClassName, bottom, onClick} = props;

  console.log(onClick)
  return (
    <a href='#' onClick={(evt) => props.onClick(evt) } style={{ width: size, height: size, bottom }}
      className={containerClassName}>
      <img
        style={{ width: size, height: size }}
        className={classes.avatar}
        src={ source || uri }
      />
    </a>
  );
};

export default compose(
  withStyles((theme) => ({
    avatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      borderRadius: AVATAR_SIZE / 2,
      objectFit: 'cover',
      [theme.breakpoints.down('sm')]: {
        width: AVATAR_SIZE_SM,
        height: AVATAR_SIZE_SM,
        borderRadius: AVATAR_SIZE_SM / 2,
      },
    },
  })),
)(AvatarRound);
