import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {withHandlers} from '@truefit/bach-recompose';
import Icon from 'react-web-vector-icons';
import {FIELDS} from '../../constants/cardData';
import {
  CONTACT_MENU_BUTTON_SIZE,
  CONTACT_MENU_HEIGHT,
} from '../../constants/cardViewConstants';
import {handleError} from '../../../helpers';
import {SHADOW_STYLE} from '../../../shared/styles/shadow';

const ContactMenuButton = ({iconName, onPress, palette, disabled}) => (
  <div onPress={onPress} disabled={disabled}>
    <Icon
      type="feather"
      name={iconName}
      size={CONTACT_MENU_BUTTON_SIZE}
      color={disabled ? `${palette.primaryColor}4D` : palette.primaryColor}
    />
  </div>
);

const DetailContactMenu = props => {
  const { data, palette, handleSendEmail, handleSendText, handleCall, classes} = props;
  const phone = data[FIELDS.mobilePhone] || data[FIELDS.phone];
  return (
    <div style={{ backgroundColor: palette.backgroundColor }} className={classes.contactMenu}>
      <ContactMenuButton
        {...props}
        iconName="mail"
        disabled={!data[FIELDS.email]}
        onPress={handleSendEmail}
      />
      <ContactMenuButton
        {...props}
        iconName="message-circle"
        disabled={!data[FIELDS.mobilePhone]}
        onPress={handleSendText}
      />
      <ContactMenuButton
        {...props}
        iconName="phone"
        disabled={!phone}
        onPress={handleCall}
      />
    </div>
  );
};

export default compose(
  withHandlers({
    // handleSendEmail: ({data}) => () =>
    //   Linking.openURL(`mailto:${data[FIELDS.email]}`),
    // handleSendText: ({data}) => () => {
    //   SendSMS.send(
    //     {
    //       body: '',
    //       recipients: [data[FIELDS.mobilePhone]],
    //       successTypes: ['sent', 'queued'],
    //       allowAndroidSendWithoutReadPermission: true,
    //     },
    //     (completed, cancelled, error) => {
    //       if (error) {
    //         handleError(
    //           error,
    //           "Oops! We couldn't send your connection a text right now.",
    //         );
    //       }
    //     },
    //   );
    // },
    // handleCall: ({data}) => () =>
    //   Linking.openURL(`tel:${data[FIELDS.mobilePhone] || data[FIELDS.phone]}`),
  }),
  withStyles(({palette}) => ({
    contactMenu: {
      flexDirection: 'row',
      width: '100%',
      opacity: 0.9,
      height: CONTACT_MENU_HEIGHT,
      justifyContent: 'space-evenly',
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      ...SHADOW_STYLE,
      shadowOffset: {
        width: 0,
        height: -4,
      },
    },
  })),
)(DetailContactMenu);
