import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {
  CARD_PADDING,
  NORMAL_FONT_SIZE,
} from '../../constants/cardViewConstants';
import {SHADOW_STYLE} from '../../../shared/styles/shadow';

const ShareButton = props => {
  const {styles, theme, onShare, isOnboarding, disabled, ...rest} = props;

  return (
    <button
      {...rest}
      onPress={onShare}
      icon={
        isOnboarding
          ? null
          : {
              type: 'feather',
              name: 'share',
              color: disabled ? undefined : theme.Button.titleStyle.color,
              opacity: disabled ? 0.3 : 1,
            }
      }
      {...styles.shareButton}
      textStyle={isOnboarding ? {paddingHorizontal: 20} : null}
      disabled={disabled}
    >{isOnboarding ? 'Sign up to save this card!' : 'Share'}</button>
  );
};

export default compose(
  withStyles(({theme, isOnboarding, disabled}) => ({
    shareButton: {
      titleStyle: {
        fontSize: NORMAL_FONT_SIZE,
        fontWeight: '600',
      },
      buttonStyle: {backgroundColor: theme.colors.primary},
      containerStyle: {
        marginVertical: CARD_PADDING,
        marginHorizontal: isOnboarding ? CARD_PADDING * 1.5 : CARD_PADDING * 3,
        ...(disabled ? {} : SHADOW_STYLE),
      },
      titleStyle: {},
    },
  })),
)(ShareButton);
