export const SHADOW_ELEVATION = 5;

export const SHADOW_STYLE = {
  // shadowColor: 'black',
  // shadowOffset: {
  //   width: 0,
  //   height: 2,
  // },
  // shadowOpacity: 0.5,
  // shadowRadius: 3.84,
  // elevation: SHADOW_ELEVATION,
};

export const CARD_SHADOW_STYLE = {
  shadowColor: 'black',
  // shadowOffset: {
  //   width: 0,
  //   height: 2,
  // },
  shadowOpacity: 0.2,
  shadowRadius: 20,
  // elevation: 2,
  paddingTop: 35,
};
