// import {BASE_CONFIG} from '../../../configure/config/config.base';

export const TEMPLATES = {
  chevron: 'chevron',
  classic: 'classic',
  full: 'full',
};

export const TEMPLATE_OPTIONS = [
  {label: 'Chevron', value: TEMPLATES.chevron},
  {label: 'Classic', value: TEMPLATES.classic},
  {label: 'Full', value: TEMPLATES.full},
];

export const getDefaultHeader = palette =>
  `https://cardsona-production.s3.amazonaws.com/defaults/${palette}.jpg`;

export const COLOR_PALETTES = [
  {
    label: 'Sky',
    value: 'sky',
    primaryColor: '#2979FE',
    primaryContrastColor: '#FFFFFF',
    secondaryColor: '#E8F0FF',
    backgroundColor: '#FFFFFF',
    backgroundContrastColor: '#3E3E3E',
    extremeBackgroundColor: '#FFFFFF',
  },
  {
    label: 'Grape',
    value: 'grape',
    primaryColor: '#6246EA',
    primaryContrastColor: '#FFFFFF',
    secondaryColor: '#F0EDFD',
    backgroundColor: '#FFFFFF',
    backgroundContrastColor: '#3E3E3E',
    extremeBackgroundColor: '#FFFFFF',
  },
  {
    label: 'Creamsicle',
    value: 'creamsicle',
    primaryColor: '#FF8861',
    primaryContrastColor: '#FFFFFF',
    secondaryColor: '#FFF3EF',
    backgroundColor: '#FFFFFF',
    backgroundContrastColor: '#3E3E3E',
    extremeBackgroundColor: '#FFFFFF',
  },
  {
    label: 'Sunset',
    value: 'sunset',
    primaryColor: '#F7F65E',
    primaryContrastColor: '#3E3E3E',
    secondaryColor: '#747447',
    backgroundColor: '#3E3E3E',
    backgroundContrastColor: '#FFF',
    extremeBackgroundColor: '#000000',
  },
  {
    label: 'Poinsettia',
    value: 'poinsettia',
    primaryColor: '#E92627',
    primaryContrastColor: '#FFF',
    secondaryColor: '#813434',
    backgroundColor: '#3E3E3E',
    backgroundContrastColor: '#FFF',
    extremeBackgroundColor: '#000000',
  },
  {
    label: 'Moss',
    value: 'moss',
    primaryColor: '#068080',
    primaryContrastColor: '#FFF',
    secondaryColor: '#275858',
    backgroundColor: '#3E3E3E',
    backgroundContrastColor: '#FFF',
    extremeBackgroundColor: '#000000',
  },
];

export const FIELDS = {
  // Card Info
  id: 'id',
  template: 'template',
  palette: 'palette',

  // User Info
  name: 'name',
  interest: 'interest',
  position: 'position',
  organization: 'organization',
  email: 'email',
  mobilePhone: 'mobilePhone',
  phone: 'phone',
  intro: 'intro',

  addressStreet1: 'addressStreet1',
  addressStreet2: 'addressStreet2',
  addressCity: 'addressCity',
  addressState: 'addressState',
  addressZip: 'addressZip',

  websiteObjects: 'websiteObjects',
  facebook: 'facebook',
  linkedIn: 'linkedIn',
  instagram: 'instagram',
  twitter: 'twitter',
  pinterest: 'pinterest',
  youtube: 'youtube',
  vimeo: 'vimeo',
  patreon: 'patreon',
  externalSocialMediaAccounts: 'externalSocialMediaAccounts',
  twitch: 'twitch',
  steam: 'steam',
  discord: 'discord',
  xboxLive: 'xboxLive',
  nintendo: 'nintendo',
  duolingo: 'duolingo',
  yelp: 'yelp',
  snapchat: 'snapchat',
  tiktok: 'tiktok',
  playstation: 'playstation',
  venmo: 'venmo',
  cashapp: 'cashapp',
  paypal: 'paypal',

  profileImageUri: 'profileImageUri',
  newProfileImage: 'newProfileImage',
  headerImageUri: 'headerImageUri',
  newHeaderImage: 'newHeaderImage',
  galleryImageUris: 'galleryImageUris',
  newGalleryImages: 'newGalleryImages',
};

export const ExternalSocialMediaAccountType = {
  SocialMedia: 'SocialMedia',
  MediaPlatforms: 'MediaPlatforms',
  GamingNetworks: 'GamingNetworks',
  PaymentNetworks: 'PaymentNetworks'
};

export const getPaletteFromValue = value =>
  COLOR_PALETTES.find(x => x.value === value) || COLOR_PALETTES[0];

export const getPaletteFromData = data =>
  getPaletteFromValue(data[FIELDS.palette]);

export const getShareMessageForCard = (
  card,
  {personalMessage, isCardOwner = true} = {},
) => {
  const cardOwnerName = isCardOwner ? 'my' : `${card[FIELDS.name]}'s`;

  let message = `I'd like to share ${cardOwnerName} ${
    card[FIELDS.interest]
  } digital contact card with you.`;

  message += '\n\nFirst download the app at https://cardsona.com/download-app';

  if (card.shareLink) {
    message += `\n\nCardsona Card Link: ${card.shareLink}`;
  }

  if (personalMessage) {
    message += `\n\n${personalMessage}`;
  }

  message += `\n\n${card[FIELDS.name]}`;

  if (card[FIELDS.organization]) {
    message += `\nOrg: ${card[FIELDS.organization]}`;
  }

  if (card[FIELDS.phone]) {
    message += `\nPhone: ${card[FIELDS.phone]}`;
  }

  if (card[FIELDS.email]) {
    message += `\nEmail: ${card[FIELDS.email]}`;
  }

  return message;
};
