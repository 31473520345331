import {createMuiTheme} from '@material-ui/core/styles';

export default createMuiTheme({
  overrides: {
    MuiTypography: {
      root: {
        color: '#3E3E3E',
      },
    },
  },
});
