import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {CARD_PADDING} from '../../constants/cardViewConstants';

const DetailsSection = props => {
  const {classes, title, palette, children} = props;
  return (
    <div className={classes.detailsSectionRoot}>
      {title ? <p style={{ color: palette.backgroundContrastColor }} className={classes.detailsSectionTitle}>{title.toUpperCase()}</p> : null}
      {children}
    </div>
  );
};

export default compose(
  withStyles((theme) => ({
    detailsSectionRoot: {
      paddingHorizontal: CARD_PADDING,
      marginTop: 2,
    },
    detailsSectionTitle: {
      marginBottom: 10,
      fontSize: 22,
      letterSpacing: -0.55,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12
      },
    },
  })),
)(DetailsSection);
