import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {ClassicBody, ClassicHeader} from './parts';
import {
  CARD_BORDER_RADIUS,
  CONTACT_MENU_HEIGHT,
} from '../../../constants/cardViewConstants';

const ClassicDetail = ({classes, ...props}) => {
  const {palette} = props;

  return (
    <div className={classes.card}>
      <div>
        <ClassicHeader
          {...props}
          height={524}
          backgroundColor={palette.secondaryColor}
        />
        <div className={classes.cardBody} style={{ backgroundColor: palette.backgroundColor }}>
          <ClassicBody {...props} backgroundColor={palette.backgroundColor} />
        </div>
      </div>
    </div>
  );
};

export default compose(
  withStyles(({palette}) => ({
    card: {
      flex: 1,
      borderRadius: CARD_BORDER_RADIUS,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      boxShadow: '0 0 34px 0 rgba(37, 7, 7, 0.06)'
    },
    cardBody: {
      flex: 1,
      paddingBottom: CONTACT_MENU_HEIGHT + 10,
    },
  })),
)(ClassicDetail);
