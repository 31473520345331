// import {logError} from './log';
// import {
//   InAppAlertService,
//   AlertTypes,
// } from '../../notifications/constants/inAppAlert';

const BAD_REQUEST = 400;

export const handleError = (e, userMessage) => {
  // if (userMessage)
  //   InAppAlertService.alert(AlertTypes.Error, 'Uh oh!', userMessage);
  // logError(e);
};

export function getValidationErrorsFromApiResponse(error) {
  const {data} = error && error.response ? error.response : null;

  if (!data) return;

  try {
    return Object.entries(data).reduce(
      (validationErrors, [errorKey, errorValue]) => {
        if (errorValue && errorValue.length) {
          validationErrors[errorKey] = errorValue[0];
        }
        return validationErrors;
      },
      {},
    );
  } catch {
    return null;
  }
}

export const handleApiError = (e, setErrors, userMessage) => {
  if (!e.response || e.response.status !== BAD_REQUEST) {
    handleError(e, userMessage);
  }

  const validationErrors = getValidationErrorsFromApiResponse(e);
  if (validationErrors) setErrors(validationErrors);
  else handleError(e, userMessage);
};
