import React from 'react';

function FullTemplateIcon({palette, ...rest}) {
  return (
    <svg height="100%" viewBox="0 0 107 107" width="100%" {...rest}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 0h85c12.15 0 22 9.85 22 22v63c0 12.15-9.85 22-22 22H22C9.85 107 0 97.15 0 85z"
          fill={palette.backgroundColor}
        />
        <path
          d="M10 10h71c8.837 0 16 7.163 16 16v55c0 8.837-7.163 16-16 16H26c-8.837 0-16-7.163-16-16z"
          fill="silver"
        />
        <rect
          fill={'gray'}
          fillOpacity={0.3}
          height={5}
          rx={2}
          width={32}
          x={38}
          y={72}
        />
        <rect
          fill={palette.primaryColor}
          height={8}
          rx={2}
          width={46}
          x={31}
          y={61}
        />
        <circle cx={54} cy={44} fill="gray" r={12} />
        <rect
          fill={palette.primaryColor}
          height={5}
          rx={2.5}
          width={18}
          x={15}
          y={15}
        />
      </g>
    </svg>
  );
}

export default FullTemplateIcon;
