import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {ChevronBody, ChevronHeader} from './parts';
import {
  CARD_BORDER_RADIUS,
} from '../../../constants/cardViewConstants';

const ChevronDetail = ({classes, ...props}) => {
  const { palette } = props;
  return (
    <div className={classes.card}>
      <div>
        <ChevronHeader {...props} height={300} />
        <div className={classes.cardBody}>
          <ChevronBody {...props} backgroundColor={palette.backgroundColor} />
        </div>
      </div>
    </div>
  );
};

export default compose(
  withStyles(() => ({
    card: {
      flex: 1,
      borderRadius: CARD_BORDER_RADIUS,
      borderTopLeftRadius: 0,
      boxShadow: '0 0 34px 0 rgba(37, 7, 7, 0.06)'
    },
    cardBody: {
      flex: 1
    },
  })),
)(ChevronDetail);
