import React from 'react';
import {
  FIELDS,
  TEMPLATES,
  getPaletteFromData,
} from '../constants/cardData';
import { ChevronDetail } from './templates/chevron';
import { ClassicDetail } from './templates/classic';
import { FullDetail } from './templates/full';

const CardDetail = props => {
  const { data } = props;
  const palette = getPaletteFromData(data);

  switch (data[FIELDS.template]) {
    case TEMPLATES.full:
      return <FullDetail {...props} palette={palette} />;

    case TEMPLATES.classic:
      return <ClassicDetail {...props} palette={palette} />;

    case TEMPLATES.chevron:
    default:
      return <ChevronDetail {...props} palette={palette} />;
  }
};

export default CardDetail;
