import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';

import Root from './Root';

const Index = (props) => {
  return (
    <Root {...props} />
  )
}


export default Index
