import React, {useState, useEffect} from 'react';
import {compose} from '@truefit/bach';
import { withStyles } from '@truefit/bach-material-ui';
import {FullBody, FullHeader} from './parts';
import {FIELDS} from '../../../constants/cardData';
import {
  CARD_BORDER_RADIUS,
  CONTACT_MENU_HEIGHT,
} from '../../../constants/cardViewConstants';
import {AvatarRound} from '../../shared';
import useIsInViewport from 'use-is-in-viewport'
import { Modal } from '@material-ui/core';
import ImageViewer from "react-simple-image-viewer";

const FullDetail = ({classes, ...props}) => {
  const [isInViewport, targetRef] = useIsInViewport()
  const {palette, data} = props

  const [opacity, setOpacity] = useState(0)
  const [visible, setVisible] = useState(false)
  const headerImage = data.newHeaderImage ? data[FIELDS.newHeaderImage].uri : data[FIELDS.headerImageUri]

  useEffect(() => {
    const img = new Image();

    img.onload = function () {
      setOpacity(1)
    };

    img.src = headerImage;
  }, [data])

  function handleClick(evt) {
    evt.preventDefault()
    setVisible(true)
  }

  return (
    <div id={`card-type-${data.template}`} className={classes.card}>
      <div className={classes.cardBackground}
        style={{ transition: 'opacity 0.15s ease', opacity, backgroundImage: `url(${headerImage})` }} />
      <div className={classes.backgroundOverlay} style={{ backgroundColor: palette.extremeBackgroundColor}}></div>
      <div
        className={classes.scrolledHeader}
        style={{ opacity: isInViewport ? 1 : 0}}>
        <AvatarRound
          onClick={handleClick}
          containerStyle={classes.scrolledAvatar}
          size={34}
          uri={data[FIELDS.profileImageUri]}
        />
        <p
          style={{ color: palette.primaryColor }}
          className={classes.scrolledName}>
          {data[FIELDS.name]}
        </p>
      </div>
      <div>
        <div className={classes.transition} style={{ opacity: isInViewport ? 0 : 1 }}>
          <FullHeader {...props} />
        </div>
        <div className={classes.cardBody} ref={targetRef}>
          <FullBody {...props} />
        </div>
      </div>
      <Modal
        disableEnforceFocus
        disableAutoFocus
        open={visible}
        onClose={() => setVisible(false)}
        classes={classes.modal}
      >
        <ImageViewer
          src={[data[FIELDS.profileImageUri]]}
          currentIndex={0}
          onClose={() => setVisible(false)}
          closeOnClickOutside={true}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
        />
      </Modal>
    </div>
  );
};

const ComposedFullDetail = compose(
  withStyles((theme) => ({
    card: {
      flex: 1,
      borderRadius: CARD_BORDER_RADIUS,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      height: '100vh',
      overflow: 'scroll',
      paddingBottom: 200
    },
    cardBackground: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      borderRadius: CARD_BORDER_RADIUS,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh',
      zIndex: -1
    },
    backgroundOverlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      borderRadius: CARD_BORDER_RADIUS,
      borderTopLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      opacity: 0.7,
      height: '100vh',
      zIndex: -1
    },
    cardBody: {
      flex: 1,
      marginTop: '20%',
      paddingBottom: CONTACT_MENU_HEIGHT + 10,
    },
    scrolledAvatar: {
      marginRight: 5,
    },
    scrolledHeader: {
      transition: 'opacity 300ms ease-out, opacity 300ms ease-in',
      alignItems: 'center',
      flexDirection: 'row',
      left: 20,
      position: 'absolute',
      top: 20,
      zIndex: 1,
      display: 'flex'
    },
    scrolledName: {
      fontSize: 16,
      fontWeight: "bold",
      marginLeft: 8
    },
    transition: {
      transition: 'opacity 300ms ease-out, opacity 300ms ease-in'
    },
    modal: {
      position: 'absolute',
      width: 400,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 8,
      textAlign: 'center'
    }
  })),
)(FullDetail);

export default ComposedFullDetail;

