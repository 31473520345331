import React, { useState } from 'react';
import { compose } from '@truefit/bach';
import { withStyles } from '@truefit/bach-material-ui';
import WebsiteLogo from './websiteLogo';
import { Modal } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const WebsiteLogoButton = ({
  classes,
  name,
  username,
  size,
  disabled,
  link,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  function handleClick(evt) {
    evt.preventDefault()
    setOpen(true)
  }

  function handleCopy(evt) {
    setCopied(true)
    setTimeout(() => {
      setOpen(false)
    }, 2000)
  }

  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  function sanitizeLink(link) {
    if(!link.match('http')) {
      return "http://" + link
    } else {
      return link
    }
  }

  const body = (
    <div className={classes.modal}>
      { link && !isValidHttpUrl(link) && <p>Platform: {link}</p>}
      { username && <p>Username: {username}</p>}
      { link && isValidHttpUrl(link) && <a target={'_blank'} href={sanitizeLink(link)} className={classes.modalButton}>Visit Website</a> }
      { username &&
        <CopyToClipboard onCopy={() => handleCopy() } text={username}>
          <button className={classes.modalButton}>
            { !copied && 'Copy Username to Clipboard' }
            { copied && 'Copied!' }
          </button>
        </CopyToClipboard>
      }
    </div>
  );

  return (
    <>
      <a
        {...rest}
        href={sanitizeLink(link)}
        onClick={(evt) => handleClick(evt)}
        className={classes.button}
        target={'_blank'}
        >
        <WebsiteLogo name={name} size={size} disabled={disabled} />
      </a>
      <Modal
        disableEnforceFocus
        disableAutoFocus
        open={open}
        onClose={() => setOpen(false)}
      >
        {body}
      </Modal>
    </>
  );
};

export default compose(
  withStyles((theme) => ({
    button: {
      display: 'inline-block',
      marginRight: 20,
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        marginRight: 10,
        marginBottom: 10,
      },
    },
    modalButton: {
      background: '#2679FF',
      borderRadius: '25.5px',
      color: 'white',
      display: 'block',
      width: 225,
      padding: '10px 20px',
      margin: '10px auto',
      textDecoration: 'none',
      borderWidth: 0,
      cursor: 'pointer'
    },
    modal: {
      position: 'absolute',
      width: 400,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 8,
      textAlign: 'center'
    }
  })),
)(WebsiteLogoButton);
