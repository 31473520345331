export const getBaseUrl = url => {
  var convertedUrl;

  if (url.includes('://')) {
    var pathArray = url.split('/');
    convertedUrl = pathArray[2].replace('www.', '');
    return convertedUrl;
  }
  convertedUrl = url.replace('www.', '');
  return convertedUrl;
};

export const getUrl = (url) => {
  var convertedUrl = url;

  if (url.includes('://')) {
    var pathArray = url.split('://');
    convertedUrl = pathArray[pathArray.length - 1]
  }

  convertedUrl = convertedUrl.replace('www.', '');

  return "http://" + convertedUrl;
}
