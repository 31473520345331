import React from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {FullHeader} from './parts';
import {FIELDS} from '../../../constants/cardData';
import {CARD_BORDER_RADIUS} from '../../../constants/cardViewConstants';

const FullCondensed = props => {
  const {styles, data} = props;
  return (
    <div style={styles.card}>
      <img
        style={styles.cardBackground}
        src={
          data.newHeaderImage
            ? data[FIELDS.newHeaderImage].uri
            : data[FIELDS.headerImageUri]
        }
      />
      <div style={styles.backgroundOverlay}></div>
      <FullHeader {...props} roundedBottomCorners condensed truncateLongText />
    </div>
  );
};

export default compose(
  withStyles(({width, height, palette}) => ({
    card: {width, height},
    cardBackground: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      borderRadius: CARD_BORDER_RADIUS,
      borderTopLeftRadius: 0,
    },
    backgroundOverlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      borderRadius: CARD_BORDER_RADIUS,
      borderTopLeftRadius: 0,
      backgroundColor: palette.extremeBackgroundColor,
      opacity: 0.7,
    },
  })),
)(FullCondensed);
