import React, { useEffect, useState } from 'react';
import { Typography, Container } from '@material-ui/core';
import { compose, } from '@truefit/bach';
import { withStyles } from '@truefit/bach-material-ui';
import CardDetail from './cardDetail'


const MainContent = ({ card, classes }) => {
  return(
    <Container maxWidth="md" className={classes.paper}>
      <CardDetail data={card} />
    </Container>
  )
};

const Header = ({ classes, card }) => {
  const [link, setLink] = useState(null)

  useEffect(() => {
    if (card) {
      var linkData = {
        data: {
          'token': card.token,
          'fromUserId': card.userId
        }
      };

      window.branch.link(linkData, function (err, branchLink) {
        setLink(branchLink)
      });
    }
  }, [card])

  const isMobile = (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i))
  const isApple = (/iPhone|isPad|isPod/).test(navigator.userAgent)
  const isAndroid = (/Android/).test(navigator.userAgent)

  const appleLink = isApple ? link : "https://apps.apple.com/us/app/cardsona/id1497915679"
  const androidLink = isAndroid ? link : "https://play.google.com/store/apps/details?id=com.cardsona.app&hl=en_US&gl=US"

  return(
    <div className={classes.headerContainer}>
      <Container maxWidth="md" className={classes.header}>
        <a style={{fontWeight: '600'}} href="https://cardsona.com" target={'_blank'} className={classes.headerLeft}>
          <img className={classes.cardsonaBlock} src='https://cardsona-production.s3.amazonaws.com/defaults/smile.png' />
          <img className={classes.cardsonaText} src='https://cardsona-production.s3.amazonaws.com/defaults/cardsona.png' />
        </a>
        <div className={classes.headerRight}>
          <Typography className={classes.cardsonaTitle} variant="body1">
            Let’s network smartly by saving my Cardsona!
          </Typography>
          <Typography className={classes.cardsonaSubtitle} variant="body2">
            <a href="https://cardsona.com" target="_blank">
              Download the Cardsona app
            </a> on a mobile device for free.
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {(isAndroid || !isMobile) && <a href={androidLink}>
            <img className={classes.download} src='https://cardsona-production.s3.amazonaws.com/defaults/android.png' />
          </a>}
          {(isApple || !isMobile) && <a href={appleLink}>
            <img className={classes.download} src='https://cardsona-production.s3.amazonaws.com/defaults/apple.png' />
          </a>}
        </div>
      </Container>
    </div>
  )
}

const CardPage = ({ classes, ...props }) => {
  return (
    <div className={classes.wrapper}>
      <Header classes={classes} {...props} />
      <MainContent classes={classes} {...props} />
    </div>
  )
}

const ComposedCardPage = compose(
  withStyles(theme => ({
    cardsonaBlock: {
      width: 50,
      height: 50
    },
    cardsonaText: {
      width: 257 / 1.2,
      height: 35 / 1.2,
      [theme.breakpoints.down('sm')]: {
        width: 257 / 2,
        height: 35 / 2
      },
      [theme.breakpoints.down('xs')]: {
        width: 257 / 3,
        height: 35 / 3
      },
    },
    cardsonaTitle: {
      fontSize: 16,
      color: '#2889BF',
      fontWeight: 'bold',
      letterSpacing: '-0.6px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        letterspacing: -0.4
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12
      },
    },
    cardsonaSubtitle: {
      fontSize: 12,
      color: '#020202',
      letterSpacing: '-0.4px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
        letterspacing: -0.3
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 10
      },
    },
    wrapper: {
      minHeight: '100vh'
    },
    paper: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      backgroundColor: 'transparent',
      position: 'relative'
    },
    headerContainer: {
      backgroundColor: 'white',
      boxShadow: "0 2px 15px 0 rgba(0, 0, 0, 0.16)"
    },
    footerContainer: {
      backgroundColor: 'white',
      boxShadow: "0 -2px 24px 0 rgba(0, 0, 0, 0.06)"
    },
    header: {
      height: 90,
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headerLeft: {
      flex: 1,
      padding: '15px 15px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        flex: 1,
      }
    },
    headerRight: {
      flex: 1.5,
      padding: '15px 0',
      [theme.breakpoints.down('sm')]: {
        padding: '25px 0',
      },
      [theme.breakpoints.down('xs')]: {
        flex: 4,
      },
    },
    footer: {
      height: 120,
      [theme.breakpoints.down('xs')]: {
        height: 110
      },
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center'
    },
    footerDownload: {
      textAlign: 'center',
      fontSize: 18,
      color: '#2889BF',
      fontWeight: 'bold',
      letterSpacing: '-0.6px',
      margin: 12,
      [theme.breakpoints.down('xs')]: {
        margin: 6
      },
    },
    download: {
      width: 206 * 0.6,
      height: 76 * 0.6,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
    loadingContainer: {
      minHeight: '80vh'
    }
  }))
)(CardPage);

export default ComposedCardPage;
