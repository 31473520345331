import React, { useEffect, useState } from 'react';
import {compose} from '@truefit/bach';
import {withStyles} from '@truefit/bach-material-ui';
import {IntroText, CardDetails} from '../../../shared';
import AddToMyCardsona from '../../../shared/addToMyCardsona';


const ChevronBody = ({classes, ...props}) => {
  const { backgroundColor, data, palette} = props;

  return (
    <div className={classes.cardBody} style={{ backgroundColor, padding: 20 }}>
      <IntroText {...props} showTriangle />
      <AddToMyCardsona {...props} />
      <CardDetails {...props} />
    </div>
  );
};

export default compose(
  withStyles(() => ({
    cardBody: {},
  })),
)(ChevronBody);
